import { useEffect } from 'react';
import { useOverlayScrollbars } from 'overlayscrollbars-react';

export const useInitializeBodyScroll = () => {
  const [initBodyOverlayScrollbars] = useOverlayScrollbars({
    defer: true
  });

  useEffect(() => {
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);
};
